import C from "./Profile.module.css";

import { apiDatetimeToDate } from "@warrenio/api-spec/conversion";
import type { User } from "@warrenio/api-spec/spec.oats.gen";
import { useSetAtom, useStore } from "jotai/react";
import { sortBy } from "remeda";
import type { Action } from "../../components/Action.tsx";
import { Badge } from "../../components/Badge.tsx";
import ContentPane from "../../components/ContentPane.tsx";
import { Separator } from "../../components/Separator.tsx";
import { WTooltip } from "../../components/WTooltip.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { LongDate } from "../../components/l10n/DateFormat.tsx";
import { ContentLoadingSuspense } from "../../components/loading/Loading.tsx";
import { DetailsHolder, DetailsTable } from "../../components/table/DetailsTable.tsx";
import { DetailsTableName } from "../../components/table/DetailsTableName.tsx";
import { DetailsTableRow } from "../../components/table/DetailsTableRow.tsx";
import { WTable, WTableBody } from "../../components/table/WTable.tsx";
import { useConfig } from "../../config.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AcceptInvitationModal } from "../access/AcceptInvitationModal.tsx";
import { accessDelegationListQuery, type AccessDelegationWithType } from "../access/delegation/apiOperations.ts";
import { accessImpersonationListQuery } from "../access/impersonation/apiOperations.ts";
import { delegationLink } from "../access/links.ts";
import { impersonationLabel } from "../access/scopeLabel.tsx";
import { logoutAction } from "../api/logoutAction.ts";
import { ResourceCreateLinks } from "../api/resourceTypeLinks.ts";
import { useStandardMutation, useStandardSuspenseQuery } from "../api/useStandardMutation.ts";
import * as billingAccountQuery from "../billing/billingAccountQuery.ts";
import { webAuthAtom } from "../login/auth0/auth0Loader.ts";
import { auth0ChangePassword } from "../login/auth0/auth0Provider.ts";
import { raiseToast } from "../notifications/toast.tsx";
import { ActAsModal } from "./ActAsModal.tsx";
import { UserAvatar } from "./UserAvatar.tsx";
import {
    activateMfaMutation,
    changeProfileDataMutation,
    deactivateMfaMutation,
    userDataAtom,
    userMfaQuery,
} from "./apiOperations.ts";

export function ProfileView() {
    //#region Hooks
    const data = useSuspenseQueryAtom(userDataAtom);
    const changeProfileData = useStandardMutation(changeProfileDataMutation);
    const { mfaAvailable } = useConfig();
    const store = useStore();
    //#endregion

    const { name, profile_data } = data;
    const { email } = profile_data;

    async function changeProfileDataPartial(newFields: Partial<User["profile_data"]>) {
        await changeProfileData.mutateAsync({ body: newFields });
    }

    async function changeProfilePassword(email: string) {
        let result;
        let err;
        try {
            result = await auth0ChangePassword(await store.get(webAuthAtom), email);
        } catch (e) {
            err = e;
        }
        if (err) {
            raiseToast({ message: String(err), type: "error" });
        } else {
            raiseToast({ message: result, type: "success" });
        }
    }

    return (
        <div className={C.ProfileHolder}>
            <div className="p-8 pb-4 flex justify-center">
                <UserAvatar user={data} />
            </div>

            <ContentPane>
                <div className="lightBlock">
                    <div className="flex justify-between flex-wrap">
                        <h1 className="font-size-heading">{name}</h1>

                        <LogoutButton />
                    </div>

                    <DetailsHolder>
                        <DetailsTable>
                            <DetailsTableName
                                title="First name:"
                                placeholder="Add first name"
                                value={profile_data.first_name ?? ""}
                                isRequired
                                onChange={(value) => changeProfileDataPartial({ first_name: value })}
                            />

                            <DetailsTableName
                                title="Last name:"
                                placeholder="Add last name"
                                value={profile_data.last_name ?? ""}
                                isRequired
                                onChange={(value) => changeProfileDataPartial({ last_name: value })}
                            />

                            <DetailsTableRow title="Mail:">{profile_data.email}</DetailsTableRow>

                            {email != null && (
                                <DetailsTableRow title="Password:">
                                    <WButton
                                        icon="jp-icon-refresh"
                                        color="primary"
                                        variant="ghost"
                                        size="xs"
                                        action={async () => await changeProfilePassword(email)}
                                    >
                                        Reset Password
                                    </WButton>
                                </DetailsTableRow>
                            )}

                            <DetailsTableRow title="Created at:">
                                <LongDate date={apiDatetimeToDate(profile_data.created_at)} />
                            </DetailsTableRow>
                        </DetailsTable>
                    </DetailsHolder>
                </div>
            </ContentPane>

            <Separator />

            <ContentPane>
                <h2 className="font-size-subtitle">Users I can access</h2>
                <ContentLoadingSuspense>
                    <ImpersonationsTable />
                </ContentLoadingSuspense>
            </ContentPane>

            <Separator />

            <ContentPane>
                <h2 className="font-size-subtitle">Users who can access my account</h2>
                <ContentLoadingSuspense>
                    <DelegationsTable />
                </ContentLoadingSuspense>
            </ContentPane>

            {mfaAvailable && (
                <>
                    <Separator />

                    <ContentPane>
                        <h2 className="font-size-subtitle">Multi-factor authentication (MFA)</h2>
                        <ContentLoadingSuspense>
                            <MfaContent />
                        </ContentLoadingSuspense>
                    </ContentPane>
                </>
            )}
        </div>
    );
}

export function LogoutButton() {
    const logout = useSetAtom(logoutAction);
    return (
        <WButton
            className="hidden-mobile"
            color="error"
            size="bar"
            variant="border"
            icon="jp-power-icon"
            action={logout}
        >
            Logout
        </WButton>
    );
}

const mfaDeactivateWorks = false;

function MfaContent() {
    const { data } = useStandardSuspenseQuery(userMfaQuery);
    const activateMutation = useStandardMutation(activateMfaMutation);
    const deactivateMutation = useStandardMutation(deactivateMfaMutation);

    async function activateMfa() {
        await activateMutation.mutateAsync();
    }

    async function deactivateMfa() {
        await deactivateMutation.mutateAsync();
    }

    const { success } = data;

    return (
        <>
            <p className="color-muted pb-4">
                You can activate multi-factor authentication for improved security. This works only for the
                username-password login method. For social login methods (Google etc.), it is usually possible to
                activate MFA on the third party side.
            </p>

            {success ? (
                <>
                    <p className="mb-2">MFA is active for your user.</p>

                    {mfaDeactivateWorks && (
                        <WButton color="primary" size="bar" variant="border" action={async () => await deactivateMfa()}>
                            Disable MFA
                        </WButton>
                    )}
                </>
            ) : (
                <WButton color="primary" size="bar" variant="border" action={async () => await activateMfa()}>
                    Enable MFA
                </WButton>
            )}
        </>
    );
}

function ImpersonationsTable() {
    const { data } = useStandardSuspenseQuery(accessImpersonationListQuery);
    const impersonations = sortBy([...data.values()], [(a) => a.created_at, "desc"]);

    return (
        <WTable>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Access scope</th>
                    <th>Date</th>
                    <th />
                </tr>
            </thead>
            <WTableBody>
                {impersonations.map((item) => {
                    return (
                        <tr key={item.uuid}>
                            <td>{item.access_owner}</td>
                            <td>{impersonationLabel(item.billing_account_name)}</td>
                            <td>
                                <LongDate date={item.created_at} />
                            </td>
                            <td>
                                {item.is_accepted ? (
                                    <ActAsModal item={item} />
                                ) : (
                                    <AcceptInvitationModal uuid={item.uuid} name={item.access_owner} />
                                )}
                            </td>
                        </tr>
                    );
                })}
            </WTableBody>
        </WTable>
    );
}

const ViewImpersonationLink = ({
    delegation,
    viewLink,
}: {
    delegation: AccessDelegationWithType;
    viewLink: Action;
}) => (
    <WTooltip text="View">
        <WButton color="primary" size="xs" variant="ghost" action={viewLink}>
            {delegation.name}
        </WButton>
    </WTooltip>
);

function DelegationsTable() {
    const { data } = useStandardSuspenseQuery(accessDelegationListQuery);
    const accounts = billingAccountQuery.useSuspense();
    const delegations = sortBy([...data.values()], [(a) => a.created_at, "desc"]);

    return (
        <WTable
            afterTable={
                <WButton
                    color="primary"
                    size="bar"
                    variant="border"
                    icon="jp-icon-add"
                    action={ResourceCreateLinks.access_delegation}
                >
                    Delegate New Access
                </WButton>
            }
        >
            <thead>
                <tr>
                    <th>Access Group</th>
                    <th>Scope</th>
                    <th>Status</th>
                    <th>Created</th>
                </tr>
            </thead>
            <WTableBody>
                {delegations.map((item) => {
                    const viewLink = delegationLink(item);

                    let acceptedSum = 0;
                    let pendingSum = 0;

                    for (const child of item.impersonations) {
                        if (child.is_accepted) {
                            acceptedSum++;
                        } else {
                            pendingSum++;
                        }
                    }

                    const billingAccountName = item.billing_account_id
                        ? (accounts.get(item.billing_account_id)?.title ?? null)
                        : undefined;

                    return (
                        <tr key={item.id}>
                            <td>
                                <ViewImpersonationLink delegation={item} viewLink={viewLink} />
                            </td>
                            <td>{impersonationLabel(billingAccountName)}</td>
                            <td>
                                {!!acceptedSum && <Badge color="success">{acceptedSum} ACCEPTED</Badge>}
                                {!!pendingSum && <Badge color="warning">{pendingSum} PENDING</Badge>}
                                {!acceptedSum && !pendingSum && "-"}
                            </td>
                            <td>
                                <LongDate date={item.created_at} />
                            </td>
                        </tr>
                    );
                })}
            </WTableBody>
        </WTable>
    );
}
